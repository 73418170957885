@font-face {
  font-family: 'LatoWeb';
  src: url('assets/fonts/Lato-Regular.eot');
  src: url('assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), 
       url('assets/fonts/Lato-Regular.woff2') format('woff2'),
       url('assets/fonts/Lato-Regular.woff') format('woff'), 
       url('assets/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'LatoWeb', -apple-system, BlinkMacSystemFont, 'LatoWeb', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  overflow-y: hidden;
  background: #F8F9FF;
}

.align-right {
  text-align: right;
}
@media (max-width: 426px) {
  .align-right {
    text-align: left;
    display: flex;
    width:100%;
    margin-top:10px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 321px) {
  .align-right {
    text-align: left;
    display: flex;
    margin-top:0px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.align-center {
  text-align: center;
}

.font-semi-bold {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.PhoneInputInput {
  height: 30px !important;
  border: 1px solid #d9d9d9 !important;
}
.PhoneInputInput:focus-visible,
.PhoneInputInput:hover,
.PhoneInputInput:active{
  border-color: #2d53da !important;
  outline: #2d53da !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

.ant-btn-primary {
  background-color: #2D53DA !important;
  border-radius: 5px;
  padding: 5px 20px;
  color: white !important;
}

.ant-btn-outlined {
  color: #2D53DA !important;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 20px;
  border: 1px solid #2D53DA !important;
}
.ant-btn-text {
  color: #2D53DA !important;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 20px;
}

.ant-btn:active, .ant-btn:hover, .ant-btn:focus {
  border-color: #2D53DA ;
  background-color: #2D53DA ;
  color: #F8F9FF ;
}

.ant-switch-checked {
  background-color: #03C83E !important;
}

::-webkit-scrollbar {
  width: 12px; 
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #E3E8FC;
  border-radius: 6px;
  font-size: 16px;
}

.styled-cursor{
  cursor:pointer;
  color: #5d5f68;
  font-size: 16px;
}

.pdf-viewer-embed {
  background-color: transparent !important;
}

.ant-menu-dark .ant-menu-item {
  background-color: #002862; 
}
.ant-picker-now-btn{
  color: #03249b !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
  border: 1px solid #2D53DA !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #fff;
  background: #2D53DA !important;
}

.ant-picker-today-btn {
  color: #2D53DA !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #2d53da !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color:  #2D53DA !important;
}

.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
  background-color:  #2D53DA !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color:  #2D53DA !important;
  border: 1px solid  #2D53DA !important;
}

.ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color:#2D53DA !important; 
}

.ant-picker-month-btn:hover{
  color:#2D53DA !important; 
}
.ant-picker-year-btn:hover{
  color:#2D53DA !important; 
}
.ant-picker-decade-btn:hover{
  color:#2D53DA !important; 
}

.ant-checkbox-wrapper {
  border-color: #2D53DA !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #2D53DA !important;
}
